import { useRef, useState, useLayoutEffect } from "react";

const useWindowBreakpoint = (test) => {
    const mediaQuery = useRef(window.matchMedia(test));
    const [matches, setMatches] = useState(mediaQuery.current.matches);
    const handleMatchToggle = ({ matches }) => setMatches(matches);

    useLayoutEffect(() => {
        const ref = mediaQuery.current;

        // Triggered at first load and if query changes
        handleMatchToggle(ref);

        ref.addEventListener("change", handleMatchToggle);

        return () => ref.removeEventListener("change", handleMatchToggle);
    }, []);

    return matches;
};

export default useWindowBreakpoint;
