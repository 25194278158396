import { useLocale } from "../../context/Locale";
import useDetectInteraction from "../../hooks/useDetectInteraction";
import styles from "./Placeholder.module.scss";

function Placeholder() {
    const { copy } = useLocale();
    const userInteracted = useDetectInteraction();

    return (
        <div className={styles.wrapper}>
            <div className={styles.copy}>
                <h3>{copy.help.title}</h3>
                <p>{copy.help.onboarding}</p>
            </div>

            <svg
                className={styles.placeholder}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 412 412"
                height="412"
                width="412"
                aria-hidden="true"
                style={{
                    "--playState": userInteracted ? "paused" : ""
                }}
            >
                <g>
                    <g>
                        <rect x="1438" className={styles.track} width="8" height="350"/>
                        <rect x="1446" className={styles.empty} width="132" height="62"/>
                        <rect x="1578" className={styles.track} width="8" height="62"/>
                        <rect x="1586" className={styles.empty} width="62" height="62"/>
                        <rect x="1236" y="62" className={styles.track} width="412" height="8"/>
                        <rect x="1446" y="70" className={styles.filled} width="202" height="62"/>
                        <rect x="1236" y="132" className={styles.track} width="412" height="8"/>
                        <rect x="1236" y="140" className={styles.empty} width="62" height="62"/>
                        <rect x="1298" y="140" className={styles.track} width="8" height="62"/>
                        <rect x="1306" y="140" className={styles.empty} width="132" height="62"/>
                        <rect x="1446" y="140" className={styles.empty} width="132" height="62"/>
                        <rect x="1578" y="140" className={styles.track} width="8" height="272"/>
                        <rect x="1236" y="202" className={styles.track} width="412" height="8"/>
                        <rect x="1236" y="210" className={styles.empty} width="132" height="62"/>
                        <rect x="1368" y="210" className={styles.track} width="8" height="62"/>
                        <rect x="1376" y="210" className={styles.filled} width="62" height="62"/>
                        <rect x="1508" y="210" className={styles.track} width="8" height="202"/>
                        <rect x="1236" y="272" className={styles.track} width="412" height="8"/>
                        <rect x="1236" y="280" className={styles.empty} width="62" height="62"/>
                        <rect x="1298" y="280" className={styles.track} width="8" height="132"/>
                        <rect x="1306" y="280" className={styles.empty} width="132" height="62"/>
                        <rect x="1236" y="342" className={styles.track} width="412" height="8"/>
                        <rect x="1236" y="350" className={styles.filled} width="62" height="62"/>
                        <rect x="1306" y="350" className={styles.empty} width="62" height="62"/>
                        <rect x="1368" y="350" className={styles.track} width="8" height="62"/>
                        <rect x="1376" y="350" className={styles.filled} width="132" height="62"/>
                        <rect x="1236" className={styles.empty} width="202" height="132"/>
                        <rect x="1586" y="280" className={styles.empty} width="62" height="132"/>
                        <rect x="1516" y="210" className={styles.filled} width="62" height="202"/>
                        <rect x="1586" y="140" className={styles.empty} width="62" height="132"/>
                        <rect x="1446" y="210" className={styles.empty} width="62" height="132"/>
                    </g>
                    <g>
                        <rect x="886" className={styles.track} width="8" height="412"/>
                        <rect x="1096" className={styles.track} width="8" height="280"/>
                        <rect x="1104" className={styles.empty} width="62" height="62"/>
                        <rect x="1166" className={styles.track} width="8" height="62"/>
                        <rect x="1174" className={styles.empty} width="62" height="62"/>
                        <rect x="824" y="62" className={styles.track} width="412" height="8"/>
                        <rect x="824" y="132" className={styles.track} width="412" height="8"/>
                        <rect x="894" y="140" className={styles.empty} width="132" height="62"/>
                        <rect x="1026" y="140" className={styles.track} width="8" height="272"/>
                        <rect x="824" y="202" className={styles.track} width="412" height="8"/>
                        <rect x="824" y="272" className={styles.track} width="412" height="8"/>
                        <rect x="824" y="342" className={styles.track} width="412" height="8"/>
                        <rect x="894" y="350" className={styles.filled} width="62" height="62"/>
                        <rect x="956" y="350" className={styles.track} width="8" height="62"/>
                        <rect x="964" y="350" className={styles.empty} width="62" height="62"/>
                        <rect x="824" className={styles.empty} width="62" height="202"/>
                        <rect x="824" y="210" className={styles.filled} width="62" height="202"/>
                        <rect x="894" className={styles.filled} width="202" height="132"/>
                        <rect x="1104" y="70" className={styles.empty} width="132" height="202"/>
                        <rect x="1034" y="280" className={styles.empty} width="202" height="132"/>
                        <rect x="1034" y="140" className={styles.empty} width="62" height="132"/>
                        <rect x="894" y="210" className={styles.empty} width="132" height="132"/>
                    </g>
                    <g>
                        <rect x="412" className={styles.empty} width="132" height="62"/>
                        <rect x="544" className={styles.track} width="8" height="412"/>
                        <rect x="754" className={styles.track} width="8" height="350"/>
                        <rect x="762" className={styles.empty} width="62" height="62"/>
                        <rect x="412" y="62" className={styles.track} width="412" height="8"/>
                        <rect x="474" y="70" className={styles.track} width="8" height="210"/>
                        <rect x="482" y="70" className={styles.empty} width="62" height="62"/>
                        <rect x="412" y="132" className={styles.track} width="412" height="8"/>
                        <rect x="412" y="202" className={styles.track} width="412" height="8"/>
                        <rect x="412" y="210" className={styles.empty} width="62" height="62"/>
                        <rect x="552" y="210" className={styles.empty} width="62" height="62"/>
                        <rect x="614" y="210" className={styles.track} width="8" height="202"/>
                        <rect x="412" y="272" className={styles.track} width="412" height="8"/>
                        <rect x="762" y="280" className={styles.empty} width="62" height="62"/>
                        <rect x="412" y="342" className={styles.track} width="412" height="8"/>
                        <rect x="622" y="350" className={styles.empty} width="202" height="62"/>
                        <rect x="762" y="70" className={styles.filled} width="62" height="202"/>
                        <rect x="412" y="280" className={styles.empty} width="132" height="132"/>
                        <rect x="482" y="140" className={styles.filled} width="62" height="132"/>
                        <rect x="412" y="70" className={styles.empty} width="62" height="132"/>
                        <rect x="552" className={styles.empty} width="202" height="202"/>
                        <rect x="622" y="210" className={styles.empty} width="132" height="132"/>
                        <rect x="552" y="280" className={styles.filled} width="62" height="132"/>
                    </g>
                    <g>
                        <rect className={styles.empty} width="202" height="62"/>
                        <rect x="202" className={styles.track} width="8" height="280"/>
                        <rect x="210" className={styles.filled} width="202" height="62"/>
                        <rect y="62" className={styles.track} width="412" height="8"/>
                        <rect x="272" y="70" className={styles.track} width="8" height="202"/>
                        <rect x="342" y="70" className={styles.track} width="8" height="342"/>
                        <rect y="132" className={styles.track} width="412" height="8"/>
                        <rect y="202" className={styles.track} width="412" height="8"/>
                        <rect y="210" className={styles.empty} width="132" height="62"/>
                        <rect x="132" y="210" className={styles.track} width="8" height="202"/>
                        <rect x="140" y="210" className={styles.empty} width="62" height="62"/>
                        <rect x="280" y="210" className={styles.empty} width="62" height="62"/>
                        <rect y="272" className={styles.track} width="412" height="8"/>
                        <rect x="62" y="280" className={styles.track} width="8" height="132"/>
                        <rect x="140" y="280" className={styles.filled} width="202" height="62"/>
                        <rect x="350" y="280" className={styles.empty} width="62" height="62"/>
                        <rect y="342" className={styles.track} width="412" height="8"/>
                        <rect x="140" y="350" className={styles.empty} width="202" height="62"/>
                        <rect x="350" y="350" className={styles.empty} width="62" height="62"/>
                        <rect y="70" className={styles.filled} width="202" height="132"/>
                        <rect x="210" y="70" className={styles.empty} width="62" height="202"/>
                        <rect x="350" y="70" className={styles.empty} width="62" height="202"/>
                        <rect x="280" y="70" className={styles.empty} width="62" height="132"/>
                        <rect y="280" className={styles.empty} width="62" height="132"/>
                        <rect x="70" y="280" className={styles.empty} width="62" height="132"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Placeholder;
