import html2canvas from "html2canvas";

export default class ExportAsImage {
    static #downloadImage(blob, fileName) {
        const fakeLink = document.createElement("a");
        fakeLink.setAttribute("hidden", "");
        fakeLink.download = fileName;
        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
    }

    static async init(element, options, fileName) {
        const canvas = await html2canvas(element, options);
        const image = canvas.toDataURL("image/png", 1.0);
        this.#downloadImage(image, fileName);
    }
}
