export const ColorYellow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect style={{ fill: "var(--yellow)" }} x=".5" y=".5" width="23" height="23" />
            <path style={{ fill: "var(--controlAccent)" }} d="M23,1V23H1V1H23m1-1H0V24H24V0h0Z" />
        </svg>
    );
};

export const ColorRed = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect style={{ fill: "var(--red)" }} x=".5" y=".5" width="23" height="23" />
            <path style={{ fill: "var(--controlAccent)" }} d="M23,1V23H1V1H23m1-1H0V24H24V0h0Z" />
        </svg>
    );
};

export const ColorBlue = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect style={{ fill: "var(--blue)" }} x=".5" y=".5" width="23" height="23" />
            <path style={{ fill: "var(--controlAccent)" }} d="M23,1V23H1V1H23m1-1H0V24H24V0h0Z" />
        </svg>
    );
};

export const ColorBlack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect style={{ fill: "var(--black)" }} x=".5" y=".5" width="23" height="23" />
            <path style={{ fill: "var(--controlAccent)" }} d="M23,1V23H1V1H23m1-1H0V24H24V0h0Z" />
        </svg>
    );
};
