import Artwork from "../Artwork";
import Actions from "../Actions";
import PropTypes from "prop-types";
import styles from "./Main.module.scss";

function Main({ mondrian }) {
    const { artwork, metadata } = mondrian;

    return (
        <div className={styles.wrapper}>
            <Artwork artwork={artwork} metadata={metadata} />
            <Actions metadata={metadata} />
        </div>
    );
}

Main.propTypes = {
    mondrian: PropTypes.shape({
        artwork: PropTypes.array.isRequired,
        metadata: PropTypes.object.isRequired
    })
};

export default Main;
