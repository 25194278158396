import PropTypes from "prop-types";
import styles from "./Artwork.module.scss";

function Artwork({ artwork, metadata }) {
    return (
        <section className={styles.gridContainer}>
            <div
                className={styles.grid}
                data-element="artwork"
                style={{
                    "--columns": metadata.canvas.columns,
                    "--rows": metadata.canvas.rows,
                    "--aspectRatio": `${metadata.canvas.width} / ${metadata.canvas.height}`
                }}
            >
                {artwork.map((block) => (
                    <div
                        className={`${styles.block} ${block.color ? styles[block.color] : ""}`}
                        key={block.id}
                        style={{
                            "--blockColumns": block.columns,
                            "--blockRows": block.rows
                        }}
                    />
                ))}
            </div>
        </section>
    );
}

Artwork.propTypes = {
    artwork: PropTypes.array,
    metadata: PropTypes.shape({
        canvas: PropTypes.object.isRequired
    })
};

export default Artwork;
