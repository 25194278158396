export const FormatSquare = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="24" height="24" />
            <path style={{ fill: "var(--controlAccent)" }} d="M23,1V23H1V1H23m1-1H0V24H24V0h0Z" />
        </svg>
    );
};

export const FormatThree = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24" width="36" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="36" height="24" />
            <path style={{ fill: "var(--controlAccent)" }} d="M35,1V23H1V1H35m1-1H0V24H36V0h0Z" />
        </svg>
    );
};

export const FormatSixteen = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 24" width="43" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="43" height="24" />
            <path style={{ fill: "var(--controlAccent)" }} d="M42,1V23H1V1H42m1-1H0V24H43V0h0Z" />
        </svg>
    );
};
