import Icon from "../Icon";
import PropTypes from "prop-types";
import styles from "./Control.module.scss";

function Control({ type, value, icon, label, category, register, isRequired }) {
    return (
        <label className={styles.control} htmlFor={value}>
            <input
                className={styles.input}
                type={type}
                id={value}
                value={value}
                {...register(category, { required: isRequired })}
            />
            <span className={styles.content}>
                <Icon icon={icon} />

                <span className={styles.labelContainer}>
                    <span>{label}</span>
                </span>
            </span>
        </label>
    );
}

Control.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};

export default Control;
