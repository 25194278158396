import { useCallback } from "react";
import { IconInfo, IconDownload } from "../Icons";
import { useLocale } from "../../context/Locale";
import ExportAsImage from "../../services/ExportAsImage";
import Tooltip from "../Tooltip";
import PropTypes from "prop-types";
import styles from "./Actions.module.scss";

const canvasOptions = {
    scale: 2.5,
    windowWidth: 1920,
    windowHeight: 1080
};

function downloadImage() {
    let artwork = document.querySelector("[data-element='artwork']");
    artwork && ExportAsImage.init(artwork, canvasOptions, "Artwork");
}

function getLocaleDate(timestamp, locale) {
    const date = new Date(timestamp);
    return date.toLocaleString(locale);
}

function Actions({ metadata }) {
    const { copy } = useLocale();
    const { canvas, blocks, timestamp } = metadata;

    const getBlocksInfo = useCallback(() => {
        return Object.keys(blocks).map((key) => ({
            label: copy.artwork[`colors_${key}`],
            value: blocks[key]
        }));
    }, [blocks, copy]);

    const tooltipContent = useCallback(() => {
        return [
            {
                title: copy.artwork.created_title,
                value: getLocaleDate(timestamp, copy.code)
            },
            {
                title: copy.artwork.format_title,
                value: canvas.width + ":" + canvas.height
            },
            {
                title: copy.artwork.complexity_title,
                value: copy.artwork[`complexity_${canvas.complexity}`]
            },
            {
                title: copy.artwork.blocks_title,
                value: getBlocksInfo()
            }
        ];
    }, [copy, timestamp, canvas, getBlocksInfo]);

    return (
        <section className={styles.wrapper}>
            <Tooltip placement="top" content={tooltipContent()}>
                <button
                    className={styles.button}
                    type="button"
                >
                    <IconInfo />
                    <span>{copy.actions.info_label}</span>
                </button>
            </Tooltip>

            <button className={styles.button} type="button" onClick={downloadImage}>
                <IconDownload />
                <span>{copy.actions.download_label}</span>
            </button>
        </section>
    );
}

Actions.propTypes = {
    metadata: PropTypes.shape({
        canvas: PropTypes.object,
        color: PropTypes.object,
        blocks: PropTypes.object,
        timestamp: PropTypes.number
    })
};

export default Actions;
