import { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Main from "../Main";
import Placeholder from "../Placeholder";
import Modal from "../Modal";
import { useLocale } from "../../context/Locale";
import useArtworkHistory from "../../hooks/useArtworkHistory";
import useWindowBreakpoint from "../../hooks/useWindowBreakpoint";
import MondrianGenerator from "../../services/MondrianGenerator";
import styles from "./Dashboard.module.scss";

function Dashboard() {
    const { copy } = useLocale();
    const [modalOpened, setModalOpened] = useState(false);
    const [mondrianStack, index, update, canNavigate, navigate] = useArtworkHistory();
    const isMobile = useWindowBreakpoint("(max-width: 60em)");

    useEffect(() => {
        let mobileAccepted = JSON.parse(localStorage.getItem("mobileAccepted"));
        isMobile && !mobileAccepted && setModalOpened(true);
    }, [isMobile]);

    function createArtwork(data) {
        let mondrian = new MondrianGenerator(...Object.values(data));
        let result = mondrian.init();

        update(data, result);
    }

    function closeModal() {
        setModalOpened(false);
        localStorage.setItem("mobileAccepted", JSON.stringify(true));
    }

    return (
        <>
            <div className={styles.wrapper}>
                <header className={styles.child}>
                    <Header />
                </header>

                <aside className={styles.child}>
                    <Sidebar
                        canNavigate={canNavigate}
                        onNavigate={navigate}
                        onCreateArtwork={createArtwork}
                    />
                </aside>

                <main className={styles.child}>
                    {mondrianStack[index] ? (
                        <Main
                            mondrian={mondrianStack[index].result}
                            key={mondrianStack[index].result.metadata.timestamp}
                        />
                    ) : (
                        <Placeholder />
                    )}
                </main>
            </div>

            <Modal
                isOpened={isMobile && modalOpened}
                onCloseModal={closeModal}
                title={copy.help.title}
                copy={copy.help.mobile}
                cta={copy.help.continue}
            />
        </>
    );
}

export default Dashboard;
