export const ComplexityLow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24" width="36" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="36" height="24" />
            <path
                style={{ fill: "var(--controlAccent)" }}
                d="M0,0V24H36V0H0ZM1,1H17V12H1V1ZM35,23H18V1h17V23Z"
            />
        </svg>
    );
};

export const ComplexityMid = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24" width="36" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="36" height="24" />
            <path
                style={{ fill: "var(--controlAccent)" }}
                d="M0,0V24H36V0H0ZM1,23V12H11v11H1Zm11,0V1h11V23H12Zm23-11h-11V1h11V12Z"
            />
        </svg>
    );
};

export const ComplexityHigh = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24" width="36" height="24">
            <rect style={{ fill: "var(--ui-gray-1)" }} width="36" height="24" />
            <path
                style={{ fill: "var(--controlAccent)" }}
                d="M0,0V24H36V0H0ZM18,1V12H10V1h8ZM1,23V12H9v11H1Zm18,0V12h7v11h-7Zm16-11h-8V1h8V12Z"
            />
        </svg>
    );
};
