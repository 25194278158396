import { useEffect } from "react";
import { inject } from "@vercel/analytics";
import Dashboard from "./components/Dashboard";

function App() {
    useEffect(() => {
        inject();
    }, []);

    return <Dashboard />;
}

export default App;
