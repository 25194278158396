import model from "../../models/model";
import { getNumberFromRange } from "../../shared/numberFromRange";

export default class Factory {
    metadata;
    matrix;
    isMatrixCompleted = false;
    blockId = 1;
    generatedBlocks = [];

    constructor(data) {
        this.canvas = data.canvas;
        this.complexity = data.complexity;
        this.colors = data.colors;
        this.colorAmount = data.colorAmount;
        this.#defineStructureFromModel();
    }

    /**
     * Store metadata based on user's response
     * and model configuration.
     */
    #defineStructureFromModel() {
        let config = model.canvas[this.canvas].complexity[this.complexity];

        this.metadata = {
            canvas: {
                ...model.canvas[this.canvas].dimensions,
                complexity: this.complexity,
                columns: config.columns,
                rows: config.rows,
                maxBlockSize: config.maxBlockSize,
            },
            color: {
                list: [],
                amount: model.colorAmount[this.colorAmount]
            },
            timestamp: Date.now()
        };

        // Check that color exists before include it
        for (let color of this.colors) {
            this.metadata.color.list.push(model.colors[color]);
        }

        // Create the bi-dimensional array
        this.matrix = Array(config.rows)
            .fill(null)
            .map(() => Array(config.columns).fill(null));
    }

    /**
     * Provide the first slot that is available (ltr).
     * @param {Array.<Array>} target - Bi-dimensional array to look at.
     * @return {object|Boolean} Slot coordinates, or false if target is completed.
     */
    #getFirstFreeSlot(target) {
        for (let i = 0; i < target.length; i++) {
            let currentRow = target[i];

            for (let j = 0; j < currentRow.length; j++) {
                if (target[i][j] === null) {
                    return {
                        x: j,
                        y: i
                    };
                }
            }
        }

        return false;
    }

    /**
     * Generate new block based on matrix
     * availability and user config.
     */
    #generateBlock() {
        let { x, y } = this.#getFirstFreeSlot(this.matrix);
        let { columns, rows, maxBlockSize } = this.metadata.canvas;
        let deltaX = 0;
        let deltaY = 0;

        // Exit if all slots are taken
        if (x === undefined || x === null) {
            this.isMatrixCompleted = true;
            return;
        }

        // Block dimensions are randomly created
        // from 1 to a given number. The greater number
        // should take matrix boundaries into account.
        let maxColumns = columns - x;
        let maxRows = rows - y;
        let blockColumns = getNumberFromRange(1, Math.min(maxColumns, maxBlockSize));
        let blockRows = getNumberFromRange(1, Math.min(maxRows, maxBlockSize));

        this.#placeBlock(blockColumns, blockRows, x, y, deltaX, deltaY);
    }

    /**
     * Place block in matrix and push it to the `generatedBlocks` array.
     * @param {number} columns - Columns amount of the block to be placed.
     * @param {number} rows - Rows amount of the block to be placed.
     * @param {number} x - Starting point - the X coordinate.
     * @param {number} y - Starting point - the Y coordinate.
     * @param {number} deltaX - Delta value over the X axis.
     * @param {number} deltaY - Delta value over the Y axis.
     */
    #placeBlock(columns, rows, x, y, deltaX, deltaY) {
        // Iterate over the current row
        // and fill the cells
        let currentRow = this.matrix[y + deltaY];
        for (let i = 0; i < currentRow.length; i++) {
            if (i === x + deltaX) {
                // If a cell is taken, make the in-progress
                // block thinner to prevent overlapping
                if (currentRow[i] !== null) {
                    columns = deltaX;
                } else {
                    currentRow[i] = this.blockId;
                }
                break;
            }
        }

        // Cell is ready: point to the next one
        if (deltaX < columns) deltaX++;

        // Row is ready, jump to next line
        if (deltaX === columns) {
            deltaY++;
            deltaX = 0;
        }

        // When last row is finished, push
        // the block and update global setup
        if (deltaY === rows) {
            this.generatedBlocks.push({ id: this.blockId, columns: columns, rows: rows });
            this.blockId++;
            return;
        }

        this.#placeBlock(columns, rows, x, y, deltaX, deltaY);
    }

    /**
     * Create blocks until `matrix` is completed.
     * FYI at this point the structure does not have colors.
     * @return {object} `metadata` and `artwork` objects.
     */
    createStructure() {
        while (!this.isMatrixCompleted) {
            this.#generateBlock();
        }

        return {
            metadata: this.metadata,
            artwork: this.generatedBlocks
        };
    }
}
