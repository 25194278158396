import { useEffect, useRef } from "react";
import { useLocale } from "../../context/Locale";
import { IconUndo, IconRedo } from "../Icons";
import useKeyPress from "../../hooks/useKeyPress";
import Panel from "../Settings/Panel";
import PropTypes from "prop-types";
import styles from "./Sidebar.module.scss";

function Sidebar({ onCreateArtwork, canNavigate, onNavigate }) {
    const { copy } = useLocale();
    const undoButton = useRef(null);
    const redoButton = useRef(null);
    const undoShortcut = useKeyPress(["z", "Z"], true);
    const redoShortcut = useKeyPress(["y", "Y"], true);

    useEffect(() => {
        if (undoShortcut && canNavigate().backwards) {
            onNavigate("backwards");
            undoButton.current.classList.add(styles.highlighted);
        }
    }, [undoShortcut]);

    useEffect(() => {
        if (redoShortcut && canNavigate().forwards) {
            onNavigate("forwards");
            redoButton.current.classList.add(styles.highlighted);
        }
    }, [redoShortcut]);

    function removeAnimationClass() {
        undoButton.current.classList.remove(styles.highlighted);
        redoButton.current.classList.remove(styles.highlighted);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.nav}>
                <h2 className={styles.title}>
                    {copy.settings.title}
                </h2>

                <div className={styles.actions}>
                    <button
                        type="button"
                        className={styles.button}
                        ref={undoButton}
                        aria-label={copy.settings.undo}
                        disabled={!canNavigate().backwards}
                        onClick={() => onNavigate("backwards")}
                        onAnimationEnd={removeAnimationClass}
                    >
                        <IconUndo />
                    </button>

                    <button
                        type="button"
                        className={styles.button}
                        ref={redoButton}
                        aria-label={copy.settings.redo}
                        disabled={!canNavigate().forwards}
                        onClick={() => onNavigate("forwards")}
                        onAnimationEnd={removeAnimationClass}
                    >
                        <IconRedo />
                    </button>
                </div>
            </div>

            <div className={styles.body}>
                <Panel onCreateArtwork={onCreateArtwork} />
            </div>
        </div>
    );
}

Sidebar.propTypes = {
    onCreateArtwork: PropTypes.func.isRequired,
    canNavigate: PropTypes.func.isRequired,
    onNavigate: PropTypes.func.isRequired
};

export default Sidebar;
