import { useState, useEffect } from "react";

const isTargetPressed = (target, list) => list.includes(target);

const useKeyPress = (targetKeys, isCombination = false) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = (e) => {
        if (e.repeat) return;

        if (isCombination) {
            if ((e.metaKey || e.ctrlKey) && isTargetPressed(e.key, targetKeys)) {
                e.preventDefault();
                setKeyPressed(true);
            }
        } else {
            if (isTargetPressed(e.key, targetKeys)) {
                e.preventDefault();
                setKeyPressed(true);
            }
        }
    };

    const upHandler = () => {
        setKeyPressed(false);
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);

    return keyPressed;
};

export default useKeyPress;
