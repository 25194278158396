import { useEffect, useState } from "react";

const useDetectInteraction = () => {
    const [userInteracted, setUserInteracted] = useState(false);

    useEffect(() => {
        const userHasInteracted = () => setUserInteracted(true);
        window.addEventListener("pointerdown", userHasInteracted, { once: true });

        return () => window.removeEventListener("pointerdown", userHasInteracted);
    }, []);

    return userInteracted;
};

export default useDetectInteraction;
