import strings from "../locales/strings";

export default class Localization {
    availableStrings = Object.keys(strings);

    #getBrowserLocale() {
        let browserLoc = window.navigator.language.slice(0, 2);
        return browserLoc.toUpperCase();
    }

    getInitialLocale() {
        let userLocale = JSON.parse(localStorage.getItem("userLocale"));
        let browserLocale;

        if (this.availableStrings.includes(this.#getBrowserLocale())) {
            browserLocale = this.#getBrowserLocale();
        }

        return userLocale || browserLocale || this.availableStrings[0];
    }
}
