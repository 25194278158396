import React, { useContext } from "react";
import Localization from "../services/Localization";
import strings from "../locales/strings";

const loc = new Localization();
const initialState = strings[loc.getInitialLocale()];

const LocaleContext = React.createContext();

// In replacement of a `switch` statement
const caseMap = Object.freeze({
    EN: strings.EN,
    ES: strings.ES
});
const reducer = (copy, action) => caseMap[action.type] ?? copy;

export function LocaleProvider({ children }) {
    const [copy, dispatch] = React.useReducer(reducer, initialState);

    return (
        <LocaleContext.Provider value={{ copy, dispatch }}>
            {children}
        </LocaleContext.Provider>
    )
}

export function useLocale() {
    return useContext(LocaleContext);
}
