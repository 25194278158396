import { useState, useEffect, useCallback } from "react";
import useEventListener from "../../hooks/useEventListener";
import useKeyPress from "../../hooks/useKeyPress";
import PropTypes from "prop-types";
import styles from "./Tooltip.module.scss";

function Tooltip({ children, placement, content }) {
    const [isVisible, setIsVisible] = useState(false);
    const escPressed = useKeyPress(["Escape"]);

    const hideTooltip = useCallback(() => {
        isVisible && setIsVisible(false);
    }, [isVisible]);

    useEffect(() => {
        escPressed && hideTooltip();
    }, [escPressed, hideTooltip]);

    function toggleTooltip(e) {
        setIsVisible((prevState) => !prevState);
        e.stopPropagation();
    }

    useEventListener("click", hideTooltip);

    return (
        <div className={styles.wrapper} onClick={toggleTooltip}>
            {children}

            {isVisible && (
                <div
                    role="tooltip"
                    className={`${styles.tooltip} ${styles[placement]}`}
                >
                    {content?.map((item, i) => (
                        <section className={styles.content} key={i}>
                            <h4 className={styles.title}>
                                {item.title}
                            </h4>

                            {typeof item.value === "string" ? (
                                <p dangerouslySetInnerHTML={{__html: item.value}} />
                            ) : (
                                item.value.map((item, i) => (
                                    <p key={i}>
                                        {item.label}: {item.value}
                                    </p>
                                ))
                            )}
                        </section>
                    ))}
                </div>
            )}
        </div>
    );
}

Tooltip.propTypes = {
    children: PropTypes.element.isRequired,
    placement: PropTypes.string,
    content: PropTypes.array.isRequired,
}

export default Tooltip;
