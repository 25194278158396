import { FormatSquare, FormatThree, FormatSixteen } from "./Assets/Format";
import { ComplexityLow, ComplexityMid, ComplexityHigh } from "./Assets/Complexity";
import { ColorYellow, ColorRed, ColorBlue, ColorBlack } from "./Assets/Color";
import PropTypes from "prop-types";

function Icon({ icon }) {
    switch (icon) {
        case "FormatSquare":
            return <FormatSquare />;
        case "FormatThree":
            return <FormatThree />;
        case "FormatSixteen":
            return <FormatSixteen />;
        case "ComplexityLow":
            return <ComplexityLow />;
        case "ComplexityMid":
            return <ComplexityMid />;
        case "ComplexityHigh":
            return <ComplexityHigh />;
        case "ColorYellow":
            return <ColorYellow />;
        case "ColorRed":
            return <ColorRed />;
        case "ColorBlue":
            return <ColorBlue />;
        case "ColorBlack":
            return <ColorBlack />;
        default:
            return <FormatSquare />;
    }
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired
};

export default Icon;
