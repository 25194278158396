const model = {
    canvas: {
        square: {
            dimensions: {
                width: 1,
                height: 1
            },
            complexity: {
                low: {
                    columns: 6,
                    rows: 6,
                    maxBlockSize: 3
                },
                mid: {
                    columns: 10,
                    rows: 10,
                    maxBlockSize: 4
                },
                high: {
                    columns: 14,
                    rows: 14,
                    maxBlockSize: 6
                }
            }
        },
        threeByTwo: {
            dimensions: {
                width: 3,
                height: 2
            },
            complexity: {
                low: {
                    columns: 8,
                    rows: 6,
                    maxBlockSize: 3
                },
                mid: {
                    columns: 12,
                    rows: 10,
                    maxBlockSize: 4
                },
                high: {
                    columns: 16,
                    rows: 14,
                    maxBlockSize: 6
                }
            }
        },
        sixteenByNine: {
            dimensions: {
                width: 16,
                height: 9
            },
            complexity: {
                low: {
                    columns: 10,
                    rows: 6,
                    maxBlockSize: 3
                },
                mid: {
                    columns: 16,
                    rows: 10,
                    maxBlockSize: 4
                },
                high: {
                    columns: 24,
                    rows: 12,
                    maxBlockSize: 6
                }
            }
        }
    },
    colors: {
        yellow: "yellow",
        red: "red",
        blue: "blue",
        black: "black"
    },
    colorAmount: {
        1: 0.25,
        2: 0.4,
        3: 0.65
    }
}

export default model;
