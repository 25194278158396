import { useLocale } from "../../context/Locale";
import Tooltip from "../Tooltip";
import styles from "./Header.module.scss";

function Header() {
    const { copy, dispatch } = useLocale();

    function handleLocChange(locale) {
        dispatch({ type: locale });
        localStorage.setItem("userLocale", JSON.stringify(locale));
    }

    return (
        <div className={styles.wrapper}>
            <h1>{copy.header.title}</h1>

            <div className={styles.buttons}>
                <Tooltip
                    placement="bottom"
                    content={[{ title: "", value: copy.about.text }]}
                >
                    <button type="button">
                        <span>{copy.header.about_label}</span>
                    </button>
                </Tooltip>

                <div className={styles.locPanel}>
                    <button
                        type="button"
                        className={copy.code === "es" ? styles.isCurrent : ""}
                        disabled={copy.code === "es"}
                        onClick={() => handleLocChange("ES")}
                    >
                        <span>{copy.header.loc_es}</span>
                    </button>

                    <button
                        type="button"
                        className={copy.code === "en" ? styles.isCurrent : ""}
                        disabled={copy.code === "en"}
                        onClick={() => handleLocChange("EN")}
                    >
                        <span>{copy.header.loc_en}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Header;
