import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from './vitals';
import { LocaleProvider } from "./context/Locale";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <LocaleProvider>
            <App />
        </LocaleProvider>
    </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
