import { useState } from "react";

const historySize = 10;
const getInitialStack = () => JSON.parse(localStorage.getItem("storedStack")) || [];

const useArtworkHistory = () => {
    const [mondrianStack, setMondrianStack] = useState(getInitialStack);
    const [index, setIndex] = useState(0);

    /**
     * @param {object} data - Settings provided by the user
     * @param {object} result - `MondrianGenerator` service output
     */
    function update(data, result) {
        // Creating temp variable to manipulate the array
        let current = mondrianStack;

        // If the user is not reviewing the most recent artwork,
        // remove newer element/s before placing the new one
        if (index > 0) {
            current = mondrianStack.slice(index);
        }

        // If the stack is at capacity, get rid of older elements
        if (current.length >= historySize) {
            current = mondrianStack.slice(0, historySize - 1);
        }

        setMondrianStack([{ data, result }, ...current]);
        setIndex(0);

        localStorage.setItem("storedStack", JSON.stringify([{ data, result }, ...current]));
    }

    /**
     * @return {object} `backwards` and `forwards` booleans
     */
    function canNavigate() {
        return {
            backwards: mondrianStack.length > 1 && index < mondrianStack.length - 1,
            forwards: index > 0
        };
    }

    /**
     * @param {string} action - Where to perform the `navigate` method
     */
    function navigate(action) {
        if (action === "backwards") {
            setIndex(index + 1);
        }

        if (action === "forwards") {
            setIndex(index - 1);
        }
    }

    return [mondrianStack, index, update, canNavigate, navigate];
};

export default useArtworkHistory;
