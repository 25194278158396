import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.scss";

function Modal({ isOpened, onCloseModal, title, copy, cta }) {
    const modal = useRef(null);

    useEffect(() => {
        isOpened ? modal?.current.showModal() : modal?.current.close();
    }, [isOpened]);

    return (
        <dialog ref={modal} className={styles.wrapper}>
            <div className={styles.head}>
                <h1>{title}</h1>
            </div>

            <p className={styles.paragraph}>{copy}</p>

            <button className={styles.button} type="button" onClick={onCloseModal}>
                {cta}
            </button>
        </dialog>
    );
}

Modal.propTypes = {
    isOpened: PropTypes.bool,
    onCloseModal: PropTypes.func,
    title: PropTypes.string,
    copy: PropTypes.string,
    cta: PropTypes.string
};

export default Modal;
