const strings = {
    EN: {
        code: "en",
        header: {
            title: "Create your own Mondrian",
            loc_es: "es",
            loc_en: "en",
            about_label: "About"
        },
        settings: {
            title: "Settings",
            undo: "Undo",
            redo: "Redo",
            cta: "Generate artwork"
        },
        about: {
            text: `Designed and developed by <a href="https://juanignacio.dev" target="_blank">Juan Ignacio Rios</a>.`
        },
        actions: {
            info_label: "Info",
            download_label: "Download"
        },
        artwork: {
            format_title: "Format",
            format_square: "1:1",
            format_three: "3:2",
            format_sixteen: "16:9",
            complexity_title: "Complexity",
            complexity_low: "Low",
            complexity_mid: "Mid",
            complexity_high: "High",
            colors_title: "Colors",
            colors_total: "Total",
            colors_yellow: "Yellow",
            colors_red: "Red",
            colors_blue: "Blue",
            colors_black: "Black",
            amount_title: "Color amount",
            amount_low: "Low",
            amount_high: "High",
            created_title: "Created",
            blocks_title: "Blocks"
        },
        help: {
            title: "Mondrian Generator",
            onboarding: "Unleash your inner artist and create unique artworks in the style of Piet Mondrian by tweaking the parameters from the left panel.",
            mobile: "Please note that the experience is optimized for desktop and may not work as well on mobile devices.",
            continue: "Got it 👍"
        }
    },
    ES: {
        code: "es",
        header: {
            title: "Crea tu propio Mondrian",
            loc_es: "es",
            loc_en: "en",
            about_label: "Acerca de"
        },
        settings: {
            title: "Ajustes",
            undo: "Deshacer",
            redo: "Rehacer",
            cta: "Generar arte"
        },
        about: {
            text: `Diseñado y desarrollado por <a href="https://juanignacio.dev" target="_blank">Juan Ignacio Rios</a>.`
        },
        actions: {
            info_label: "Info",
            download_label: "Descargar"
        },
        artwork: {
            format_title: "Formato",
            format_square: "1:1",
            format_three: "3:2",
            format_sixteen: "16:9",
            complexity_title: "Complejidad",
            complexity_low: "Baja",
            complexity_mid: "Media",
            complexity_high: "Alta",
            colors_title: "Colores",
            colors_total: "Total",
            colors_yellow: "Amarillo",
            colors_red: "Rojo",
            colors_blue: "Azul",
            colors_black: "Negro",
            amount_title: "Cantidad de color",
            amount_low: "Baja",
            amount_high: "Alta",
            created_title: "Creación",
            blocks_title: "Bloques",
        },
        help: {
            title: "Generador Mondrian",
            onboarding: "Libera tu artista interior y crea obras de arte únicas inspiradas en el estilo de Piet Mondrian, ajustando los parámetros del panel izquierdo.",
            mobile: "Por favor, ten en cuenta que la experiencia está optimizada para ordenadores y podría no funcionar tan bien en dispositivos móviles.",
            continue: "Entendido 👍"
        }
    }
};

export default strings;
