import { useLocale } from "../../../context/Locale";
import { useForm } from "react-hook-form";
import Control from "../Control";
import PropTypes from "prop-types";
import styles from "./Panel.module.scss";

const defaultConfig = {
    format: "square",
    complexity: "low",
    colors: ["yellow", "red", "blue"],
    colorAmount: "1"
};

const getDefaultValues = () => {
    let storedStack = JSON.parse(localStorage.getItem("storedStack")) || [];

    if (storedStack.length > 0) return storedStack[0].data;
    return defaultConfig;
};

function Panel({ onCreateArtwork }) {
    const { copy } = useLocale();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: "onTouched",
        defaultValues: getDefaultValues()
    });

    function onSubmit(values) {
        onCreateArtwork(values);
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inner}>
                <section className={styles.section}>
                    <h3>{copy.artwork.format_title}</h3>

                    <fieldset>
                        <Control
                            type="radio"
                            value="square"
                            icon="FormatSquare"
                            label={copy.artwork.format_square}
                            category="format"
                            register={register}
                        />

                        <Control
                            type="radio"
                            value="threeByTwo"
                            icon="FormatThree"
                            label={copy.artwork.format_three}
                            category="format"
                            register={register}
                        />

                        <Control
                            type="radio"
                            value="sixteenByNine"
                            icon="FormatSixteen"
                            label={copy.artwork.format_sixteen}
                            category="format"
                            register={register}
                        />
                    </fieldset>
                </section>

                <section className={styles.section}>
                    <h3>{copy.artwork.complexity_title}</h3>
                    <fieldset>
                        <Control
                            type="radio"
                            value="low"
                            icon="ComplexityLow"
                            label={copy.artwork.complexity_low}
                            category="complexity"
                            register={register}
                        />

                        <Control
                            type="radio"
                            value="mid"
                            icon="ComplexityMid"
                            label={copy.artwork.complexity_mid}
                            category="complexity"
                            register={register}
                        />

                        <Control
                            type="radio"
                            value="high"
                            icon="ComplexityHigh"
                            label={copy.artwork.complexity_high}
                            category="complexity"
                            register={register}
                        />
                    </fieldset>
                </section>

                <section className={styles.section}>
                    <h3 className={errors.colors ? styles.hasErrors : ""}>
                        {copy.artwork.colors_title}
                    </h3>
                    <fieldset>
                        <Control
                            type="checkbox"
                            value="yellow"
                            icon="ColorYellow"
                            label={copy.artwork.colors_yellow}
                            category="colors"
                            register={register}
                            isRequired={true}
                        />

                        <Control
                            type="checkbox"
                            value="red"
                            icon="ColorRed"
                            label={copy.artwork.colors_red}
                            category="colors"
                            register={register}
                            isRequired={true}
                        />

                        <Control
                            type="checkbox"
                            value="blue"
                            icon="ColorBlue"
                            label={copy.artwork.colors_blue}
                            category="colors"
                            register={register}
                            isRequired={true}
                        />

                        <Control
                            type="checkbox"
                            value="black"
                            icon="ColorBlack"
                            label={copy.artwork.colors_black}
                            category="colors"
                            register={register}
                            isRequired={true}
                        />
                    </fieldset>
                </section>

                <section className={styles.section}>
                    <h3>{copy.artwork.amount_title}</h3>
                    <fieldset>
                        <input
                            type="range"
                            min="1"
                            max="3"
                            aria-valuemin="1"
                            aria-valuemax="3"
                            {...register("colorAmount")}
                        />
                    </fieldset>
                    <div className={styles.rangeLabels} aria-hidden="true">
                        <span>{copy.artwork.amount_low}</span>
                        <span>{copy.artwork.amount_high}</span>
                    </div>
                </section>
            </div>

            <div className={styles.buttonContainer}>
                <button type="submit" disabled={Object.keys(errors).length > 0}>
                    <span>{copy.settings.cta}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="22" viewBox="0 0 32 22">
                        <path
                            className={styles.shape1}
                            d="M5.5,13.5L8,19l2.5-5.5L16,11l-5.5-2.5L8,3L5.5,8.5L0,11L5.5,13.5z"
                        />
                        <polygon
                            className={styles.shape2}
                            points="18,8 16.75,5.25 14,4 16.75,2.75 18,0 19.25,2.75 22,4 19.25,5.25"
                        />
                        <polygon
                            className={styles.shape3}
                            points="18,22 16.75,19.25 14,18 16.75,16.75 18,14 19.25,16.75 22,18 19.25,19.25"
                        />
                    </svg>
                </button>
            </div>
        </form>
    );
}

Panel.propTypes = {
    onCreateArtwork: PropTypes.func.isRequired
};

export default Panel;
