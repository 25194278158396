import Factory from "./Grid/Factory";
import Painter from "./Grid/Painter";

export default class MondrianGenerator {
    /**
     * @param {string} canvas
     * @param {string} complexity
     * @param {Array.<string>} colors
     * @param {string} colorAmount
     */
    constructor(canvas, complexity, colors, colorAmount) {
        this.data = {
            canvas: canvas,
            complexity: complexity,
            colors: colors,
            colorAmount: colorAmount
        };
    }

    /**
     * @return {{metadata: object, artwork: object}}
     */
    init() {
        let factory = new Factory(this.data);
        let painter = new Painter(factory.createStructure());

        return painter.applyColors();
    }
}
