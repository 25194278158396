export default class Painter {
    blocksToPaint;
    colorIndex;

    constructor(structure) {
        this.metadata = structure.metadata;
        this.artwork = structure.artwork;
        this.#setup();
    }

    /**
     * Define the amount of blocks to paint and a
     * where to start picking colors.
     */
    #setup() {
        // Make sure that the colors the user provided
        // are present at least once
        this.blocksToPaint = Math.max(
            Math.floor(this.artwork.length * this.metadata.color.amount),
            this.metadata.color.list.length
        );

        // Randomize the starting point
        // of the painting process
        this.colorIndex = Math.floor(Math.random() * this.metadata.color.list.length);

        // Update metadata with `blocks` info
        this.metadata = {
            ...this.metadata,
            blocks: {
                total: this.artwork.length,
            }
        }
    }

    #placeColor() {
        let currentColor = this.metadata.color.list[this.colorIndex]

        // Pick a random block
        let block = this.artwork[Math.floor(Math.random() * this.artwork.length)];

        // If it does not have a color, apply one
        if (!block.color) {
            block.color = currentColor;

            // Metadata color count
            if (!this.metadata.blocks[currentColor]) {
                this.metadata.blocks[currentColor] = 1;
            } else {
                this.metadata.blocks[currentColor]++;
            }

            // Prepare next iteration
            this.colorIndex = (this.colorIndex + 1) % this.metadata.color.list.length;
            this.blocksToPaint--;
        }
    }

    /**
     * Coloring process
     * @return {object} Response from Factory with colors in place.
     */
    applyColors() {
        while (this.blocksToPaint) {
            this.#placeColor();
        }

        return {
            metadata: this.metadata,
            artwork: this.artwork
        };
    }
}
